import React from 'react'
import {
    Link
} from "react-router-dom";
import { FaBars, FaFacebook } from "react-icons/fa";

function Navbar() {
  function showSidebar(){
    const sidebar = document.querySelector("#Sidebar");
        sidebar.style.display = 'flex';
  }
  return (
    <>
        <div id='Topbar' className='hideOnMobile'>
            <a className='topbar-item fb hideOnMobile' href='https://www.facebook.com/profile.php?id=100076211836731&mibextid=LQQJ4d'><FaFacebook /></a>    
            <Link className='topbar-item' to="/"><img alt='logo' src={require('./imgs/logo.png')}/></Link>
            <a className='topbar-item hideOnMobile' href='tel:(402) 515-2654'>(402) 515-2654</a>
            <FaBars onClick={showSidebar} className='close hideOnPc' />
        </div>
        <div id='Navbar'>
            <Link className='nav-item hideOnMobile' to="/">Home</Link>
            <Link className='nav-item hideOnMobile' to="/about">About</Link>
            <Link className='nav-item hideOnMobile' to="/service">Service</Link>
            <Link className='nav-item hideOnMobile' to="/employment">Emoployments & Careers</Link>
            <Link className='nav-item hideOnMobile' to="/contact">Contact</Link>
        </div>
    </>
  )
}

export default Navbar