import React from 'react'
import { FaFacebook } from 'react-icons/fa'

function Footer() {
  return (
    <div id='Footer'>
        <a href='https://www.facebook.com/profile.php?id=100076211836731&mibextid=LQQJ4d'><FaFacebook/></a>
        <h2>Care from Within</h2>
        <h2>7905 L St. Ste 420 Omaha, NE. 68127</h2>
        <a href='tel:(402)515-2654'>(402) 515-2654</a>
        <div className='copyright'>
            <h3>Copyright © 2021 Care from Within - All Rights Reserved.</h3>
            <h3>Follow & Like us on Facebook!</h3>
        </div>
    </div>
  )
}

export default Footer