import React from 'react'

function Card(props) {
  return (
    <div id='Card'>
        <div className='card-img'>
            <img src={require(`../imgs/${props.img}`)} alt='about' />
        </div>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
    </div>
  )
}

export default Card