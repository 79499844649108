import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer';

function Home() {
  return (
    <div id='Home'>
      <div className='bg-img'>
        <div className='welcoming'>
          <h1>Hello, We're here to CARE!</h1>
          <p>Specializing in the unique and individualized care needed for your loved one.</p>
        </div>
      </div>
      <div className='home-section-two'>
        <h2>All the services you need, within one place</h2>
        <p>We understand how important it is to have reliable and caring support for your loved ones. We offer individualized services tailored to accommodate all of your needs. Let us provide the care they deserve.</p>
        <div className='btn'>
          <Link to={'/service'} className='btn-text'>Find Out More!</Link>
        </div>
      </div>
      <div className='home-section-three'>
        <h2>Welcome to Care from Within - Your Trusted Home Care Provider</h2>
        <div className='btn'>
          <Link to={'/contact'}>Sechedule a Free Consultation Now!</Link>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Home