import React from 'react'
import {
Outlet
} from "react-router-dom";
import Navbar from './Navbar';
import Sidebar from './Sidebar';

function Root() {
  return (
    <div id='Root'>
        <Navbar />
        <Sidebar />
        <div id='Bar'>
            <Outlet/>
        </div>
    </div>
  )
}

export default Root