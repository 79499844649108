import React from 'react'
import Card from './Card'
import Footer from './Footer';

function About() {
  return (
    <div id='About'>
      <h1 className='page-header'>About Care from Within</h1>
      <div className='row'>
        <Card img={'about1.webp'} title='Our Mission' description='At Care from Within, our mission is to provide compassionate and reliable home care services to our clients. We strive to improve their quality of life and help them maintain independence in the comfort of their own homes.'/>
        <Card img={'about2.webp'} title='Our Services' description='We offer a wide range of services including personal care, house keeping, hygiene assistance, transportation, grocery shopping, errands, medication reminders, lawn care, snow removal, & companionship. Our team of trained professionals will work with you and your family to create a personalized care plan that meets your unique needs.'/>
        <Card img={'about3.webp'} title='Our Caregivers' description='Our caregivers are experienced, compassionate, and dedicated to providing the highest quality of care to our clients. They undergo rigorous training and background checks to ensure they are qualified to provide the care you need.'/>
        <Card img={'about4.webp'} title='Contact Us' description="If you have any questions or would like to schedule a consultation, please don't hesitate to contact us. We are available 24/7 and always happy to help. Consultations are free!"/>
      </div>
      <Footer />
    </div>
  )
}

export default About