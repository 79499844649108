import React from 'react'
import Card from './Card'
import Footer from './Footer'

function Service() {
  return (
    <div id='Service'>
      <h1 className='page-header'>In-Home Health Care Services</h1>
      <div className='row'>
        <Card img={'card1.webp'} title='Our Mission' description='At Care from Within, our mission is to provide compassionate and reliable home care services to our clients. We strive to improve their quality of life and help them maintain independence in the comfort of their own homes.'/>
        <Card img={'card2.webp'} title='Grocery & Errands' description='We provide transportation and/or will do it all for you while you stay in the comfort of your home.'/>
        <Card img={'card3.webp'} title='Personal Care' description='Our caregivers assist with activities of daily living, including bathing, dressing, meal preparation, hygiene and companionship.'/>
      </div>
      <div className='row'>
        <Card img={'card4.webp'} title='Meal Preparation' description='Our caregivers can help with meal planning, grocery shopping, and meal preparation, ensuring seniors have nutritious and delicious meals.'/>
        <Card img={'card5.webp'} title='Lawn Care & Snow Removal' description='Snow and lawn care removal involves the seasonal maintenance of outdoor spaces. Both services are essential for ensuring safe and well-maintained outdoor areas throughout the year.'/>
        <Card img={'card6.webp'} title='Medication Reminders' description='Our nurses and caregivers can help seniors manage their medications, ensuring they take the right medications at the right times.'/>
      </div>
      <div className='row'>
      <Card img={'card7.webp'} title='Transportation Services' description='We provide transportation services to medical appointments, social events, and errands, ensuring seniors can stay active and engaged.'/>
      </div>
      <Footer/>
    </div>
  )
}

export default Service