import React from 'react'
import { FaFacebook } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { MdOutlineClose } from "react-icons/md";

function Sidebar() {
    function hideSidebar(){
        const sidebar = document.querySelector("#Sidebar");
        sidebar.style.display = 'none';
    }
  return (
    <div id='Sidebar'>
        <MdOutlineClose className='close' onClick={hideSidebar} />
        <Link className='nav-item' to="/">Home</Link>
        <Link className='nav-item' to="/about">About</Link>
        <Link className='nav-item' to="/service">Service</Link>
        <Link className='nav-item' to="/employment">Emoployments & Careers</Link>
        <Link className='nav-item' to="/contact">Contact</Link>
        <a className='topbar-item' href='tel:(402) 515-2654'>(402) 515-2654</a>
        <a className='topbar-item fb fbicon' href='https://www.facebook.com/profile.php?id=100076211836731&mibextid=LQQJ4d'><FaFacebook className='fbicon' /></a>
    </div>
  )
}

export default Sidebar