import React from 'react'
import Footer from './Footer'

function Employment() {
  return (
    <div id='Employment'>
        <h1 className='page-header'>Employment & Career</h1>
        <div className='steps'>
          <form method="post" enctype="multipart/form-data" action='https://carefromwithinhomehealth.com/upload.php'>
          <h2>Step 1: Download the Employment Application.</h2>
          <div className='btn'><a href='https://carefromwithinhomehealth.com/application.pdf'>Download</a></div>
          <h2>Step 2: Digitally fill and Sign the Application. You can fill it with any pdf reader such as (acrobat, adobe, etc)</h2>
          <h2>Step 3: Upload the filled Application.</h2>
          <div className='upload' >
            <input type='file' name='file' id='file' required/>
            <button className='btn'>Submit</button>
          </div>
          </form>
        </div>
        <Footer/>
    </div>
  )
}

export default Employment