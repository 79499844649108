import React from 'react';
import Root from './Root';
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service';
import Employment from './pages/Employment';
import Contact from './pages/Contact';

function App() {
  return (
    <div id='App'>
      <BrowserRouter basename='/'>
        <Root/>
        <Routes>
          <Route path='/' index element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/service' element={<Service/>} />
          <Route path='/employment' element={<Employment/>} />
          <Route path='/contact' element={<Contact/>} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App