import React from 'react'
import Footer from './Footer'

function Contact() {
  return (
    <div id='Contact'>
      <h1 className='page-header'>Get In Touch!</h1>
      <form method='Post' action='https://carefromwithinhomehealth.com/contact.php'>
        <input type='text' placeholder='Name*' name='name' />
        <input type='email' placeholder='Email*' name='email' />
        <textarea placeholder='Message' rows={10} name='message'></textarea>
        <button className='btn' type='submit'>Send</button>
      </form>
      <Footer/>
    </div>
  )
}

export default Contact